<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col md="8">
        <v-card flat outlined>
          <v-row>
            <v-col class="pb-0 mx-3" md="4"
              ><v-text-field
                class="mt-2"
                v-model="name"
                outlined
                placeholder="اسم المجموعة "
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row justify="start" class="ma-0 mt-2">
            <v-col>
              <v-data-table :dark="$store.state.isDarkMode"
                :headers="headers"
                :items="names"
                item-key="id"
                @click:row="selectName"
                hide-default-footer
                style="
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  cursor: pointer;
                "
                :loading="loading"
              ></v-data-table>
            </v-col>
            <v-col>
              <v-data-table
                :headers="selectedHeaders"
                :items="selectedNames"
                item-key="id"
                hide-default-footer
                style="border: 1px solid #ccc; border-radius: 4px"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-6">
          <v-col cols="2">
            <v-btn color="primary" class="save-button" @click="submit()">{{
              $t("save")
            }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="cancel" color="error" class="cancel-button">{{
              $t("cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  async created() {
    await axios.get("/hr/employee").then((res) => {
      this.names = res.data.data;
      this.loading = false;
    });
  },
  data() {
    return {
      name: "",
      headers: [{ text: "Name", value: "name" }],
      selectedHeaders: [{ text: "Selected Name", value: "name" }],
      names: [],
      selectedNames: [],
      loading: true,
    };
  },
  methods: {
    cancel() {
      this.$router.push("/HR/initiate");
    },
    selectName(item) {
      for (let i = 0; i < this.names.length; i++) {
        if (this.names[i] == item) {
          this.names.splice(i, 1);
        }
      }
      this.selectedNames.push(item);
    },
    submit() {
      for (let i = 0; i < this.selectedNames.length; i++) {
        this.selectedNames[i] = { id: this.selectedNames[i].id };
      }
      axios
        .post("/hr/group", {
          name: this.name,
          employees: this.selectedNames,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تم إاضافة مجموعة جديدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
          this.$router.push("/HR/initiate");
        });
    },
  },
};
</script>